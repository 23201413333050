<template>
  <div class="sgsLeft" v-if="htData.length > 0 || cornerData.length > 0">
    <!-- <span v-for="(item, index) in sgsData" :key="index"
      >{{item}}</span
    > -->
    <span v-if="htData.length">{{$t("home.HT")+':'+htData.join('-')}}</span>
    <div v-if="htData.length && cornerData.length" class="score"></div>
    <span v-if="cornerData.length">{{$t("home.Corner")+':'+cornerData.join('-')}}</span>
  </div>
</template>

<script>
import { tygList } from "@/utils/filedList";

export default {
  name: "SgsLeft",
  props: ["data"],
  data() {
    return {
      tygList,
    };
  },
  computed: {
    htData(){
      if(!this.data.sgs) return []
      let mapTemp = [];
      // let clkPe = this.data.clk ? this.data.clk.pe : 0
      this.data.sgs.forEach((item) => {
        // let isHt =
        //   clkPe >= 1006 && clkPe <= 1009
        //     ? item.pe == 1004 && item.tyg == 5
        //     : item.pe == 1002 && item.tyg == 5;
        let isHt = item.pe == 1002 && item.tyg == 5;
        if (isHt) {
          mapTemp = item.sc
        }
      });
      return mapTemp
    },
    cornerData() {
      if(!this.data.sgs) return []
      const _data = this.data.sgs.reduce((prev, item) => {
        if((item.pe == 1001 || item.pe == 1013) && item.tyg == 6){
          prev = prev.length > 0 ? prev : [0, 0]
          prev[0] = prev[0] + item.sc[0]
          prev[1] = prev[1] + item.sc[1]
        }
        return prev

      }, [])
      return _data
    },
    sgsData() {
      if (!this.data.sgs) return [];
      const mapTemp = [];
      let clkPe = this.data.clk ? this.data.clk.pe : 0
      this.data.sgs.forEach((item) => {
        let isHt =
          clkPe >= 1006 && clkPe <= 1009
            ? item.pe == 1004 && item.tyg == 5
            : item.pe == 1002 && item.tyg == 5;
        if (isHt) {
          mapTemp.push(this.$t("home.HT") + `:${item.sc.join("-")}`);
        }
        if (item.pe == 1000 && item.tyg == 6) {
          mapTemp.push(this.$t("home.Corner") + `:${item.sc.join("-")}`);
        }
      });
      return mapTemp.reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
.sgsLeft {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #8d9198;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  .score {
    // margin: 0 4px;
    width: 11.3px;
  }
  // span {
  //   margin-right: 8px;
  //   &:last-child {
  //     margin-right: 0;
  //   }
  // }
}
</style>
