<template>
  <div class="dateTab">

    <div class="content" ref="content">
      <div class="contentFlex hid-scrollbar">
        <div
          :class="['item', { active: active == 'all' }]"
          @click="$emit('update:active', 'all')"
        >
          {{$t('home.All')}}
        </div>
        <div
          v-for="(item, index) in dateData"
          :key="index"
          :class="['item', { active: active == item.dateTime }]"
          @click="$emit('update:active', item.dateTime)"
        >
         {{ item.dateFormat }}
         {{ item.formatWeek }}
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: "DateTab",
  props: ["dateData", "active"],
  methods: {
    sceollHandle(right) {
      let scrollLeft = this.$refs.content.scrollLeft + (right ? 97 : -97);
      this.$refs.content.scrollTo(scrollLeft, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.dateTab {
  display: flex;
  height: 38px;
  overflow: hidden;
  line-height: 38px;
  margin: 8px 8px 4px;
  .left,
  .right {
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #193ADD;
    }
  }
  .content {
    flex: 1;
    
    display: flex;
    overflow: hidden;
    background: #fff;
    padding: 0 6px;
    border-radius: 8px;
    .contentFlex {
      overflow: auto;
      white-space: nowrap;
    }
    .item {
      display: inline-block;
      padding: 0 14px;
      height: 26px;
      border-radius: 100px;
      line-height: 26px;
      width: auto;
      cursor: pointer;
  
    }
    .active {
      background: #193ADD;
      color: #fff;
    }
  }
}
</style>

<style lang="scss">
.dark{
  .dateTab .content{
    background: #151618;
    color: #AAAFC9;
    .active{
      color: #fff;
    }
  }
}
</style>