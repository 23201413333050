<template>
  <div class="sgsCenter">
    <div :class="['rank']" style="padding-left: 5px">
      <div class="red" v-if="redData.length > 0 && redData[0] > 0">{{ redData[0] }}</div>
      <div class="yellow" v-if="yellowData.length > 0 && yellowData[0] > 0">{{ yellowData[0] }}</div>
      <div :class="['tms', { low: leftLow }]">
        {{ data.tms[0] && data.tms[0].na }}
      </div>
      <img
        v-if="data.tms[0] && data.tms[0].lurl"
        :src="data.tms[0].lurl"
        alt=""
      />
      <img
        v-else
        :src="require('@/assets/imgs/comm/outDefault'+themeHotfix+'.svg')"
        alt=""
      />
    </div>
    <div :class="['score']">
      <template v-if="data.st == 4 || data.st === undefined">
        <img
          :src="require(`@/assets/imgs/schedule/waitTime.svg`)"
          class="waitTime"
          alt=""
        />
      </template>
      <template v-else>
        <span :class="{ low: leftLow }">{{ scoreData[0] }}</span>
        <span class="line">-</span>
        <span :class="{ low: rightLow }">{{ scoreData[1] }}</span>
      </template>
    </div>
    <div :class="['rank']" style="padding-right: 5px">
      <img
        v-if="data.tms[1] && data.tms[1].lurl"
        :src="data.tms[1].lurl"
        alt=""
      />
      <img
        v-else
        :src="require('@/assets/imgs/comm/outDefault'+themeHotfix+'.svg')"
        alt=""
      />

      <div :class="['tms', { low: rightLow }]">
        {{ data.tms[1] && data.tms[1].na }}
      </div>
      <div class="red" v-if="redData.length > 0 && redData[1] > 0">{{ redData[1] }}</div>
      <div class="yellow" v-if="yellowData.length > 0 && yellowData[1] > 0">{{ yellowData[1] }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SgsCenter",
  props: ["data"],
  computed: {
    themeHotfix() {
      return this.common.theme == 'dark' ? '-dark' : ''
    },
    ...mapState(["common"]),
    scoreData() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 5) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev

      }, []);
      return _data;
    },

    leftLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] < this.scoreData[1];
    },
    rightLow() {
      if (this.data.st == 4 || this.data.st === undefined) {
        return false;
      }
      return this.scoreData[0] > this.scoreData[1];
    },
    redData() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 8) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev

      }, []);
      return _data;
    },
    yellowData() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 7) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev

      }, []);
      return _data;
    },
  },
};
</script>

<style lang="scss" scoped>
.sgsCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-weight: 500;
  & > * {
    // margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
  }
  .waitTime {
    width: 20px;
    height: 20px;
  }
  .red,
  .yellow {
    padding: 0 1px;
    color: #fff;
    background-color: #ea3443;
    height: 12px;
    width: 8px;
    text-align: center;
    line-height: 12px;
    font-size: 8px;
    border-radius: 2px;
    margin-right: 2px;
    display: block;
  }
  .score {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    width: 52px;
    font-family: "DIN";
    font-style: normal;
    font-weight: 700;

    span {
      flex: 1;
      text-align: right;
      &:last-child {
        text-align: left;
      }
    }
    .line {
      flex: none;
      margin: 0 2px;
    }
  }
  .yellow {
    background-color: #ffba07;
  }

  .rank {
    flex: 1;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    .tms {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // & > * {
    //   margin-right: 8px;
    //   &:last-child {
    //     margin-right: 0;
    //   }
    // }
    img {
      width: 30px;
      // height: 30px;
      margin-left: 8px;
    }
    &:last-child {
      img {
        margin-left: 0;
        margin-right: 8px;
      }
      .yellow,.red{
        margin-right: 0;
        margin-left: 2px;
      }
      justify-content: left;
    }
  }
  .low {
    opacity: 0.8;
  }
}
</style>

<style lang="scss">
.dark{
  .sgsCenter {
    .rank {
      .tms {
        color: #fff;
      }
    }
    .score{
      color: #fff;
    }
  }
}
</style>