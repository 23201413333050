<template>
  <div class="leftStateWrap">
    <template v-if="data.st == 5">
      <div v-if="data.clk && data.clk.pe" :class="['peState',{'textCenter': !checkClkState(), 'leftRight': checkClkState()}]">
        {{ match_period[data.clk.pe][getLocal()] }}
      </div>
      <div class="time textCenter" v-if="checkClkState()">
        {{ secTotime(timeNum) }}'
      </div>
    </template>
    <template v-else>
      <div class="textCenter state">{{ text }}</div>
      <div class="startTime leftRight">{{ time }}</div>
    </template>
  </div>
</template>

<script>
import { tygList, stList, match_period } from "@/utils/filedList";
import { Format } from "@/utils/fn";
export default {
  name: "SgsLeft",
  props: ["data"],
  data() {
    return {
      tygList,

      timeNum: 0,
      num: 1,
      timer: null,
      match_period,
    };
  },
  computed: {
    text() {
      return stList[this.data.st === undefined ? 4 : this.data.st][
        this.getLocal()
      ];
    },
    time() {
      return Format(new Date(this.data.btm), "hh:mm");
    },
  },
  created() {
    if (this.data.st == 5) {
      this.startTime();
    }
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    "data.clk.s": {
      handler() {
        this.startTime();
      },
    },
    "data.clk.r": {
      handler() {
        this.startTime();
      },
    },
  },
  methods: {
    checkClkState() {
      const notSt = [
        1001, 1005, 1009, 1010, 1011, 1012, 1013, 1014, 1015, 1016,
      ];

      if (!this.data.clk || !this.data.clk.pe) return false;
      if (notSt.includes(this.data.clk.pe)) return false;
      if (
        this.data.clk.s === undefined ||
        this.data.clk.s === null ||
        this.data.clk.s === ""
      )
        return false;

      // if (!this.data.clk.r) {
      //   return false;
      // }
      return true;
    },
    startTime() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      if (!this.checkClkState()) {
        return;
      }
      this.timeNum = this.data.clk.s;

      if (this.data.clk.tp == 1) {
        this.num = 1;
      } else {
        this.num = -1;
      }
      if(this.data.clk.r){
        this.timer = setInterval(() => {
          this.timeNum += this.num;
          if (this.data.clk.tp != 1 && this.timeNum == 0) {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    secTotime(s) {
      var t = "00:00";
      if (s > -1) {
        t = "";
        var min = Math.floor(s / 60);
        var sec = s % 60;

        if (min < 10) {
          t += "0";
        }
        t += min + ":";
        if (sec < 10) {
          t += "0";
        }
        t += sec;
      }
      return t;
    },
  },
};
</script>

<style lang="scss" scoped>
.leftStateWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  & > div {
    overflow: hidden;
    white-space: nowrap;
  }
  .time {
    font-size: 13px;
    font-weight: 500;
    color: #EA3443;
  }
  .startTime {
    color: #8d9198;
  }
  .state {
    font-size: 13px;
    color: #5a6375;
    font-weight: 500;
  }
  .leftRight{
      max-width: 66px;
      overflow: hidden;
  }
}
</style>

<style lang="scss">
.dark{
  .peState{
    color: #AAAFC9;
  }
}
</style>