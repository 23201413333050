import dayjs from 'dayjs'
const diffUtcMinute = () => dayjs().utcOffset()
const AmericaTimeDiffUtcMinute = () => -4 * 60

export const WEEK_FORMAT = () => ({
  0: {ZH:'周日', EN: 'Sun'},
  1: {ZH:'周一', EN: 'Mon'},
  2: {ZH:'周二', EN: 'Tue'},
  3: {ZH:'周三', EN: 'Wed'},
  4: {ZH:'周四', EN: 'Thu'},
  5: {ZH:'周五', EN: 'Fri'},
  6: {ZH:'周六', EN: 'Sat'},
})
//将23:59时间格式转化成23'59"
export const formatTimeType = value => {
  return value.replace(':', "'") + '"'
}
export const formatAllDayTime = diffDay => {
  return dayjs(diffDay).format('YYYY-MM-DD HH:mm:ss')
}

export const formatAllDay = (diffDay, format) => {
  return dayjs(diffDay).format(format)
}

export const getWeek = date => {
  const { $W } = dayjs(date || '')
  return WEEK_FORMAT()[$W]
}

export const fetchBeginEndTime = (diffDay = 0, unit = 'day') => {
  return {
    beginTime: dayjs()
      .add(diffDay, unit)
      .startOf(unit)
      .valueOf(),
    endTime: dayjs()
      .add(diffDay, unit)
      .endOf(unit)
      .valueOf(),
  }
}

export const fetchBeginEndInToday = (diffDay = 0, unit = 'day') => {
  if (diffDay > 0) {
    return {
      beginTime: dayjs()
        .startOf(unit)
        .valueOf(),
      endTime: dayjs()
        .add(diffDay, unit)
        .endOf(unit)
        .valueOf(),
    }
  } else {
    return {
      beginTime: dayjs()
        .add(diffDay, unit)
        .startOf(unit)
        .valueOf(),
      endTime: dayjs()
        .endOf(unit)
        .valueOf(),
    }
  }
}

export const fetchStartEnd = (diffDay = 0, unit = 'day') => {
  let endTime = dayjs()
    .endOf(unit)
    .valueOf()
  if (diffDay == -1) {
    endTime = dayjs()
      .endOf(unit)
      .subtract(-diffDay, unit)
      .valueOf()
  }
  return {
    startTime: dayjs()
      .startOf(unit)
      .subtract(-diffDay, unit)
      .valueOf(),
    endTime,
  }
}

export const time2AmericaFormat = date => {
  return dayjs(date)
    .add(diffUtcMinute(), 'minute')
    .subtract(AmericaTimeDiffUtcMinute(), 'minute')
    .valueOf()
}

export const fetchStartTime = date => {
  return dayjs(date)
    .startOf('day')
    .valueOf()
}

export const fetchEndTime = date => {
  return dayjs(date)
    .endOf('day')
    .valueOf()
}

export const fetchAmericaDayFormat = (diffDay = 0, unit = 'day') => {
  const usaTime = dayjs()
    .add(diffDay, unit)
    .tz('America/La_Paz')
  const beginTime = usaTime.startOf(unit)
  const endTime = usaTime.endOf(unit)

  return {
    beginTime,
    endTime,
    usaTime,
  }
}
