<template>
  <div
    :class="['scheduleItem', { hasOvertime: getOvertime.length }]"
    @click="backFn"
  >
    <div class="top">
      <div class="group" v-if="data.text">{{ data.text }}</div>
      <SgsTop :data="data" />
    </div>
    <SgsCenter :data="data" />
    <SgsLeft v-if="data" :data="data" />

    <!-- <div class="right">
      <i class="el-icon-arrow-right"></i>
    </div> -->
    <div class="overtime" v-if="getOvertime.length">
      <span v-for="(item, key) in getOvertime" :key="key"
        >{{
          sgsPeList[item.pe][getLocal()] +
          "[" +
          item.sc[0] +
          "-" +
          item.sc[1] +
          "]"
        }}
        {{ key + 1 != getOvertime.length ? " , " : "" }}</span
      >

      <!-- <span v-if="point.length">{{ `${sgsPeList[1006][getLocal()]}[${point[0]}-${point[1]}]`}}</span>
      <span v-if="point.length && overtime.length"> , </span>
      <span v-if="overtime.length">{{ `${sgsPeList[1013][getLocal()]}[${overtime[0]}-${overtime[1]}]`}}</span> -->
    </div>
  </div>
</template>

<script>
import SgsLeft from "./SgsLeft";
import SgsTop from "./SgsTop";

import SgsCenter from "./SgsCenter";
import { mapState } from "vuex";
import { sgsPeList } from "@/utils/filedList";
import db from '@/utils/localStorage'
import { Toast } from 'vant';

export default {
  name: "ScheduleItem",
  props: ["data"],
  components: { SgsLeft, SgsCenter, SgsTop },
  data() {
    return {
      sgsPeList,
    };
  },
  computed: {
    point() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 5) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev;
      }, []);
      return _data;
    },
    overtime() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1006 || item.pe == 1013) && item.tyg == 5) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev;
      }, []);
      return _data;
    },

    getOvertime() {
      // if (this.data.st != 0) return [];
      let clkPe = this.data.clk ? this.data.clk.pe : 0;

      if (this.data.sgs) {
        return this.data.sgs.filter((item) => {
          let is90Min =
            clkPe >= 1006 && clkPe <= 1009 && item.pe == 1001 && item.tyg == 5;
          return (
            (item.pe == 1006 && item.tyg == 5) ||
            (item.pe == 1013 && item.tyg == 5) ||
            is90Min
          );
        });
      }

      return [];
    },

    backurl() {
      return this.common.backUrl;
    },
    ...mapState(["common"]),
  },
  methods: {
    backFn() {
      if (!this.data.id || this.data.st === undefined || this.data.st === null){
        Toast(
          {
            className: 'customMsg',
            message: this.$i18n.t('home.nodata'),
          }
        )
        return;
      }
      const languageType = db.get("languageType", "")
      const _token = this.common.token
      const _nickname = this.common.nickname
      const _color = this.common.theme

      let params = '?type=100';
      if(languageType){
        params = params + '&language=' + languageType
      }
      if(_token){
        params = params + '&token=' + _token
      }
      if(_nickname){
        params = params + '&nickname=' + _nickname
      }
      if(_color){
        params = params + '&color=' + _color
      }
      const _url =
        this.backurl +
        `/index.html#/${
          this.data.st === 0 ? "result-detail" : "match-detail"
        }?backcup=1&matchId=` +
        this.data.id+params;
      console.log(_url);
      location.href = _url;
    },
  },
};
</script>
<style lang="scss">
.customMsg{
  min-width: auto;
  padding: 0 30px;
  line-height: 30px;
  height: 30px;
  background: #131A3E;
  border: none;
  border-radius: 5px;
}
</style>
<style lang="scss" scoped>
.scheduleItem {
  // display: flex;
  // height: 55px;
  // align-items: center;
  border-bottom: 1px solid #eaedf6;
  color: #131a3e;
  cursor: pointer;
  padding: 8px 0 5px 0;
  position: relative;
  overflow: hidden;
  min-height: 80px;
  &.hasOvertime {
    padding-bottom: 18px;
  }
  .top {
    display: flex;
    align-items: center;
    // position: relative;
    margin-bottom: 6px;
    // width: calc(50% - 30px);
    width: calc(50% - 55px);
    justify-content: space-between;
    margin-left: 8px;
    .group {
      text-align: center;
      line-height: 31px;
      height: 17px;
      background: #f4f4f4;
      border-radius: 5px;
      line-height: 17px;
      padding: 0 5px;
      font-weight: 500;
      font-size: 12px;
      color: #131a3e;
      max-width: 60px;
      overflow: hidden;
      white-space: nowrap;
    }
    .type {
      color: #5a6375;
      margin-left: 12px;
      display: flex;
      align-items: center;
      span {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .right {
    display: flex;
    height: 55px;
    align-items: center;
    padding: 5px;
    i {
      cursor: pointer;
      color: #5a6375;
      &:hover {
        color: #ca0a44;
      }
    }
  }
  .overtime {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    font-size: 10px;
    background: #D9E1FA;
    color: #131A3E;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: 10px;
  }
}
</style>

<style lang="scss">
.scheduleItem {
  .textCenter {
    position: absolute;
    left: calc(50%);
    transform: translateX(calc(-50%));
    font-weight: 500;
    overflow: hidden;
    font-size: 13px;
    color: #5a6375;
    width: 90px;
    white-space: nowrap;
    margin: 0;
    padding: 0 4px;
    text-align: center;
  }
}
.dark{
  .scheduleItem{
    border-bottom: 1px solid #2B2C30;
    .top {
      .group {
        background: #000000;
        color: #FFFFFF;
      }
    }
    .overtime {
      background: #111D40;
      color: #fff;
    }
  }
}
</style>
